<template>
    <div>
    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="pageNum"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="avatarUrl" align="center" label="头像">
          <template slot-scope="scope">
              <img class="avatar" :src="scope.row.avatarUrl" />
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="昵称"></el-table-column>
        <el-table-column prop="num" align="center" label="扫码次数"></el-table-column>
<!--        <el-table-column prop="integral" align="center" label="积分"></el-table-column>-->
        <el-table-column prop="phone" align="center" label="手机号"></el-table-column>
        <el-table-column prop="" align="center" label="操作">
          <template slot-scope="scope">
<!--            <el-button type="text" @click="goRecord(scope.row)">积分记录</el-button>-->
            <el-button type="text" @click="goScanRecord(scope.row)">扫码记录</el-button>
            <el-button type="text" @click="goVerifycord(scope.row)">验证记录</el-button>
            <el-button type="text" @click="showVisitHot(scope.row)">扫码地域</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <!--热度弹窗-->
      <el-dialog
        title="热度排行"
        :visible.sync="dialogVisible"
        append-to-body="true"
        width="50%"
        :before-close="handleClose">
        <div>
          <commonTable
              :tableData="dialogData"
              @handleSizeChange="handledSizeChange"
              @handleCurrentChange="handledCurrentChange"
              :currentPage="dPageNum"
              :total="dTotal"
            >
              <template v-slot:table>
                <el-table-column
                  prop="area"
                  label="区域"
                  align="center"
                  >
                </el-table-column>
                <el-table-column
                  prop="scanNum"
                  label="扫码次数"
                  align="center"
                  >
                </el-table-column>
              </template>
        </commonTable>
          <!-- <el-table
            :data="dialogData"
            style="width: 100%">
            <el-table-column
              prop="area"
              label="区域"
              >
            </el-table-column>
            <el-table-column
              prop="scanNum"
              label="扫码次数"
              >
            </el-table-column>
          </el-table> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>

    </div>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import { get, post } from "@/utils/request";
export default {
  name: "vipManage",
  components: {
    commonTable,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      dialogVisible: false,

      dialogData: [],
      dTotal: 0,
      dPageNum: 1,
      dPageSize: 10,
      dUserId: null,
    }
  },
  created() {
      this.getTableData()
  },
  methods: {
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getTableData();
    },
    handledSizeChange(num) {
      this.dPageSize = num
      this.dPageNum = 1
      this.getDialogData()
    },
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    handledCurrentChange(num) {
      this.dPageNum = num;
      this.getDialogData();
    },
    getDialogData() {
      let params={
        pageNum: this.dPageNum,
        pageSize: this.dPageSize,
        userId: this.dUserId,
      }
      get("/dealer/memberHeat", params).then((res) => {
        this.dialogData = res.list
        this.dTotal = res.total
        this.dialogVisible = true
      })
    },
    getTableData() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      get("/dealer/memberList", params).then((res) => {
        this.tableData = res.list;
        this.total = res.total
      });
    },
    goRecord(data) {
      this.$router.push({
        path:`/vipRecord`,
        query: {
          userId : data.userId
        }
      })
    },
    goScanRecord(data) {
      this.$router.push({
        path:`/vipScanRecord`,
        query: {
          userId : data.userId
        }
      })
    },
    goVerifycord(data){
      this.$router.push({
        path:`/verifyRecord`,
        query: {
          userId : data.userId
        }
      })
    },
    // 热度弹窗
    showVisitHot(row) {
      this.dUserId = row.userId
      this.getDialogData()
    }

  }
}
</script>
<style scoped>
.avatar{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
</style>
